import React, { useState } from "react"
// import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Achtsamkeit = <StaticImage src="../assets/images/therapie-achtsamkeit.jpg" className="w-full h-full max-h-52" alt=""/>
const Schematherapie = <StaticImage src="../assets/images/schematherapie-muenchen.jpg" className="w-full h-full max-h-52" alt=""/>
const ACT = <StaticImage src="../assets/images/acceptance-and-commitment-therapy-muenchen.jpg" className="w-full h-full max-h-52" alt=""/>
const Dialektisch = <StaticImage src="../assets/images/dialektisch-behaviorale-therapie.jpg" className="w-full h-full max-h-52" alt=""/>
const Traumatherapie = <StaticImage src="../assets/images/traumatherapie-muenchen.jpg" className="w-full h-full max-h-52" alt=""/>
const Paartherapie = <StaticImage src="../assets/images/paartherapie-muenchen.jpg" className="w-full h-full max-h-52" alt=""/>
const Emotionsfokussiert = <StaticImage src="../assets/images/emotionsfokussierte-therapie.jpg" className="w-full h-full max-h-52" alt=""/>

const Therapieansaetze = [
    {
        name: "Achtsamkeit",
        description: "Achtsamkeitsbasierte Techniken ergänzen mein therapeutisches Angebot, um eine nachhaltige Veränderung und eine langfristige psychische Gesundheit zu erreichen. Achtsamkeit meint die bewusste Aufmerksamkeitslenkung auf das Hier & Jetzt ohne wertende Haltung. Eine annehmende Haltung für innere und äußere Prozesse kann dabei helfen, negative Gedankenkreise zu unterbrechen, eine akzeptierende Haltung gegenüber unangenehmen Emotionen einzunehmen und positive Emotionen zu fördern. Gerade in unserer westlichen (Arbeits-)Welt sind wir es gewohnt, an vieles gleichzeitig zu denken. Wie das Dreieck der Verhaltenstherapie aus Verhalten – Gedanken – Gefühlen zeigt, beeinflusst dies jedoch unsere Stimmung und unser Wohlbefinden. Eine achtsame Haltung kann das Kontrollerleben auf körperliches und psychisches Wohlbefinden nachhaltig verbessern. Das Programm Mindfulness-Based Stress Reduction (MBSR), entwickelt von Prof. Jon Kabat-Zinn, ist ein achtsamkeitsbasiertes Verfahren bei der Behandlung von Stresserkrankungen, (chronischen) Schmerzen, Ängsten und Depressionen. Die Mindfulness-Based Cognitive Therapy (MBCT) hilft in der Vorbeugung von schweren depressiven Rückfällen. Achtsamkeit ist auch ein wichtiger Bestandteil in der dialektisch-behavioralen Therapie (DBT). Von der Achtsamkeit abzugrenzen sind Entspannungstechniken wie z.B. Progressive Muskelentspannung (PMR) oder autogenes Training. Diese Techniken können ebenso eine psychotherapeutische Behandlun flankieren, indem sie z.B. eine erhöhte Grundanspannung im Körper reduzieren helfen.",
        img: Achtsamkeit,
    },
    {
        name: "Schematherapie",
        description: "Als Weiterentwicklung der kognitiven Verhaltenstherapie nutze ich auch Techniken aus der Schematherapie nach J.E. Young. In der Arbeit mit inneren Anteilen, die jeder von uns besitzt, können erlebnisorientiert vor allem Schwierigkeiten im zwischenmenschlichen Bereich und bei chronifizierten Beschwerden bearbeitet werden. Frühere lebensgeschichtliche Erfahrungen, z.B. (emotionale) Vernachlässigung, Gewalt oder Überbehütung können unsere Grundbedürfnisse wie Kontrolle, Bindung, Selbstwert oder Autonomie frustrieren. Um dies zu kompensieren, werden Bewältigungsmechanismen („Schemata“) entwickelt, die früher (überlebens)wichtig, im jetzigen Leben jedoch Schwierigkeiten bereiten können. Die unterschiedlichen Schemata können aufgearbeitet und Anteile wie „das innere Kind“ oder „der gesunde Erwachsene“ gestärkt werden, um so langfristig die eigene Bedürfniswelt zu erkennen.",
        img: Schematherapie,
    },
    {
        name: "Acceptance and Commitment Therapy (ACT)",
        description: "Die Akzeptanz- und Commitmenttherapie (ACT) nach S.C. Hayes verknüpft die Verhaltenstherapie mit achtsamkeits- und werteorientierten Konzepten. Sie hilft im Umgang mit dysfunktionalen Gedanken und negativ bewerteten Emotionen, indem durch eine annehmende, akzeptierende Haltung emotionale Distanz eingenommen werden und der Fokus auf das eigene Handeln nach individuellen Werten und Zielen gelegt wird. Die Arbeit mit eigenen Werten und Wertesystemen ist hilfreich, das eigene Leben sinnstiftend und nach den jeweiligen Bedürfnissen zu gestalten.",
        img: ACT,
    },
    {
        name: "Dialektisch-behaviorale Therapie",
        description: "Die Dialektisch-Behaviorale Therapie (DBT), basierend auf der kognitiven Verhaltenstherapie und entwickelt von Marsha M. Linehan, kommt in der Behandlung von Borderline-Persönlichkeitsstörungen und von (komplexen) posttraumatischen Belastungsstörungen zum Einsatz. Auch in der Behandlung von Essstörungen haben sich Behandlungsansätze aus der DBT etabliert. Neben Übungen aus der Achtsamkeit werden „Skills“ (Fertigkeiten, z.B. Igelball oder starkes Duftöl) trainiert, um Anspannungszustände zu reduzieren und z.B. Selbstverletzungen vorzubeugen. Mit Übungen aus den Modulen „Zwischenmenschliche Fertigkeiten“, „Umgang mit Gefühlen“, „Stresstoleranz“ und „Selbstwert“ lernen die Betroffenen, eigene Emotionen zu regulieren, Beziehungen einzugehen und aufrecht zu erhalten sowie langfristig ein positives Selbstbild aufzubauen.",
        img: Dialektisch,
    },
    {
        name: "Traumatherapie",
        description: "Ein traumatherapeutisches Vorgehen ist indiziert beim Vorliegen vorangegangener traumatischer Erfahrungen (wie z.B. körperlicher oder sexueller Gewalt, Unfälle), die nach wie vor großes Leid erzeugen. Man spricht dann von sogenannten „Traumafolgestörungen“. Das erlebte Trauma wurde meist nicht im autobiografischen Gedächtnis abgespeichert, und vermittelt so dem Gehirn die Qualität des „Wiedererlebens“ in körperlichen und psychischen Symptomen (z.B. Alpträume, Flashbacks, erhöhte Wachsamkeit und Nervosität). Eine Psychotherapie hilft, das Erlebte in das autobiografische Gedächtnis als vergangenes Ereignis abzulegen und somit die Symptome zu lindern. Basierend darauf kann das eigene Leben neu sortiert und ausgerichtet werden. Achtsamkeitsbasierte Techniken und auch sogenannte „Skills“ (Fertigkeiten, siehe DBT) unterstützen die Behandlung.",
        img: Traumatherapie,
    },
    {
        name: "Paartherapie",
        description: "Positive Lebensereignisse, wie z.B. die Geburt eines Kindes, Umzug oder Jobwechsel, können Paare vor große Herausforderungen stellen. Aber auch (psychische) Erkrankungen des/der Partners/in oder familiäre Konflikte können sich belastend auf die Paardynamik auswirken. Veränderungsprozesse und die Anpassung an neue Rollen und Aufgaben können zu dysfunktionalen Interaktions- und Kommunikationsmustern führen. Diese können im psychotherapeutischen Gespräch aufgedeckt und bearbeitet werden, um wieder ein erfülltes Zusammenleben zu ermöglichen. Eine Einzeltherapie, die zunächst auf die Auseinandersetzung mit den eigenen Sichtweisen fokussiert, kann bei Bedarf durch Paargespräche ergänzt werden. Alternativ können von Beginn an paartherapeutische Angebote wahrgenommen werden.",
        img: Paartherapie,
    },
    {
        name: "Emotionsfokussierte Therapie",
        description: "Die Emotionsfokussierte Therapie (EFT) wurde u.a. von Leslie S. Greenberg entwickelt. Emotionen sind wie ein Kompass, der durch das Leben führt - und steuern unbewusst das Verhalten. Manche Menschen haben keine Möglichkeit, diesen emotionalen Kompass zu erlernen oder auszuformen – d.h. Gefühle wahrzunehmen, zu verstehen, zu verarbeiten und einen regulierenden Umgang damit zu finden. In sogenannten „Gefühlsbrücken“ kann es passieren, dass alte Gefühle aus vergangenen, verletzenden Erfahrungen in der Gegenwart reaktualisiert werden. Die früher gelernte Verhaltensreaktion wird oft automatisch angewendet und kann dazu führen, dass sich negative Erfahrungen wiederholen. Ein emotionales Nachlernen und somit ein wirkungsvoller Umgang mit den eigenen Emotionen sowie der Zugang zu Ressourcen stärkenden Gefühlen ist durch die erlebnisorientierte emotionsfokussierte Therapie möglich. Deshalb ergänzen diese Techniken mein therapeutisches Angebot, um eine nachhaltige Veränderung und eine langfristige psychische Gesundheit zu erreichen.",
        img: Emotionsfokussiert,
    },
]

const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    const classShow = !isReadMore && "is-read-more"
    return (
      <span className={classShow}>
        {text.slice(0, 240)}
        <span className="read-more-ellipsis">...</span><span className="hidden read-more-text">{text.slice(240)}</span>
        <button onClick={toggleReadMore} className="block w-auto px-2 my-2 text-xs max-w-fit btn--outline">
          {isReadMore ? "weiterlesen" : " weniger anzeigen"}
        </button>
      </span>
    );
};

const moreTherapiePage = () => (
  <Layout>
    <Seo
        title="Therapieansätze | Psychotherapie München Haidhausen | Privatpraxis"
        description="Kognitive Verhaltenstherapie - ergänzt durch Methoden aus der Schematherapie, Traumatherapie, DBT, Achtsamkeit & emotionsfokussierten Therapie"
    />

    <div className="relative">
        <div className="w-full h-96">
            <StaticImage className="w-full h-full" src="../assets/images/weitere-therapieansaetze.jpg" alt="Klangschale hinter drei kleinen Deko-Vasen auf Holzregal" />
            <div className="absolute top-0 w-full h-full opacity-30 bg-gradient-to-r from-transparent via-black to-transparent"></div>
            <div className="absolute top-0 flex flex-wrap items-center w-full h-full place-items-center">
                <div className="w-full mx-auto text-center text-white max-w-prose">
                    <h1 className="mb-4 lg:text-4xl">Weitere Therapieansätze</h1>
                    <div className="px-4 sm:text-lg">Die Grundlage meiner therapeutischen Arbeit - die Verhaltenstherapie - ergänze ich durch wissenschaftlich fundierte weitere Methoden.</div>
                </div>
            </div>
        </div>
    </div>
    <div className="max-w-screen-lg my-12 boxed">
        <dl className="space-y-12 md:space-y-16">
            {Therapieansaetze.map((Therapieansatz) => {
                return (
                    <div 
                        key={Therapieansatz.name} 
                        className="grid md:gap-x-16 gap-x-6 gap-y-2 md:grid-cols-5"
                        id={Therapieansatz.name}
                    >
                        <div className="order-2 md:order-1 md:col-span-3">
                            <dt className="mt-2">{Therapieansatz.name}</dt>
                            <dd className="mt-1 text-sm leading-normal text-gray-900"><ReadMore>{Therapieansatz.description}</ReadMore></dd>
                        </div>
                        <div className="md:order-2 md:col-span-2">
                            {Therapieansatz.img}
                        </div>
                    </div>

                )
            })}
        </dl>
    </div>
  </Layout>
)

export default moreTherapiePage

